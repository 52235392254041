@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  position: relative;
  display: flex;
  overflow: hidden;
  background: var(--cst-cards-category-background-default);
  border-bottom-left-radius: var(--cornerradius12);
  border-bottom-right-radius: var(--cornerradius12);
  padding: 12px 14px;

  @include fixedSize(100%, 70px); //320

  @include min-428-break {
    @include fixedSize(100%, 94px); //428
    border-bottom-left-radius: var(--cornerradius14);
    border-bottom-right-radius: var(--cornerradius14);
    padding: 18.5px 14px;
  }

  @include min-744-break {
    border-bottom-left-radius: var(--cornerradius16);
    border-bottom-right-radius: var(--cornerradius16);
    padding: 18.5px 28px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
    align-items: flex-start;
    width: 100%;

    @include min-428-break {
      gap: 6px;
    }

    .title {
      color: var(--cst-cards-category-title-default);
      @include typography-m;
      @include weight-semi-bold;

      @include min-428-break {
        @include typography-xl;
        @include weight-semi-bold;
      }
    }

    .subtitle {
      color: var(--cst-cards-category-subtitle-hover);
      @include typography-s;
      @include weight-medium;

      @include min-428-break {
        @include typography-base;
        @include weight-medium;
      }
    }
  }
}

.item-image {
  z-index: 1;
  position: absolute;
  pointer-events: none;
  bottom: -31px;
  right: -19px;

  @include min-428-break {
    bottom: -41px;
    right: -25px;
  }

  @include min-744-break {
    bottom: -41px;
    right: 15px;
  }
}

.background {
  position: absolute;
  bottom: -51px;
  right: -32px;

  z-index: 0;
  @include singleFixedSize(118px);

  @include min-428-break {
    @include singleFixedSize(157px);
    bottom: -70px;
    right: -33px;
  }

  @include min-744-break {
    bottom: -61px;
    right: 0;
  }
}

.item {
  z-index: 1;
  pointer-events: none;

  @include singleFixedSize(92px);

  @include min-428-break {
    @include singleFixedSize(124px);
  }
}
